import React, { useEffect, useRef, useState } from 'react';
import { Link as Scroll } from 'react-scroll';

import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";




const AnimationWrapper = (props) => {
    const { onAnimationEnd } = props;
    const videoRef = useRef();
    const [fadeOut, setFadeOut] = useState(false);
  
    useEffect(() => {
        const currentVideo = videoRef.current;

        const handleAnimationEnd = () => {
            setFadeOut(true);
            setTimeout(onAnimationEnd, 250);
        };

        currentVideo.addEventListener('ended', handleAnimationEnd);
    
        return () => {
          currentVideo.removeEventListener('ended', handleAnimationEnd);
        };
    }, [onAnimationEnd]);
  
    return (
        <div className={`animation-wrapper ${fadeOut ? 'fade-out' : ''}`}>
            <video ref={videoRef} className="animation-video" src="/movies/top_animation.mp4" autoPlay muted playsInline></video>
        </div>
    );
  };

const Top = () => {
    const [animationEnded, setAnimationEnded] = useState(false);
    const [fadeInContent, setFadeInContent] = useState(false);

    const onAnimationEnd = () => {
        setAnimationEnded(true);
        setTimeout(() => {
            setFadeInContent(true);
        }, 250);
    };

    return (
        <React.Fragment>
            {!animationEnded && <AnimationWrapper onAnimationEnd={onAnimationEnd} />}
            <div id='top' className={`${fadeInContent ? 'fade-in' : ''}`}>
                <div className="main-content">
                    <div className='item-list'>
                        <Swiper
                            modules={[Autoplay]}
                            spaceBetween={0}
                            slidesPerView={1}
                            loop
                            autoplay={{ delay: 0 }}
                            speed={15000}
                            allowTouchMove={false}
                        >
                            <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/top1-min.jpg'} alt="" /></SwiperSlide>
                            <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/top2-min.jpg'} alt="" /></SwiperSlide>
                            <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/top3-min.jpg'} alt="" /></SwiperSlide>
                            <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/top4-min.jpg'} alt="" /></SwiperSlide>

                        </Swiper>
                    </div>
                    <div className='scroll-area'>
                        <Scroll to='about' smooth={true} duration={1000}>
                            <img className='scroll' src={process.env.PUBLIC_URL + '/images/all_circle.png'} alt="" />
                        </Scroll>
                        {/* <a href="#about">
                            <video
                                src={process.env.PUBLIC_URL + '/movies/all_circle.mp4'} // 動画ファイルへのパスを指定
                                className='scroll'
                                autoPlay // 自動再生
                                muted // ミュート
                                loop // ループ再生
                                playsInline // iOSデバイスでインライン再生
                            />
                        </a> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Top