

const Icons = () => {
    return (
        <div id='icons'>
            <a href="tel:0493-81-6300">
                <div className='call'>
                    <img src={process.env.PUBLIC_URL + '/images/call.png'} alt="" />
                </div>
            </a>
            <a href="mailto:liina.dancestudio@gmail.com">
                <div className='email'>
                    <img src={process.env.PUBLIC_URL + '/images/email.png'} alt="" />
                </div>
            </a>
            <a href="https://www.instagram.com/liina_dancestudio" target="_blank" rel="noopener noreferrer">
                <div className='instagram'>
                    <img src={process.env.PUBLIC_URL + '/images/instagram.png'} alt="" />
                </div>
            </a>
        </div>
    )
}

export default Icons