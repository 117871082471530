import React, { useEffect } from "react";

const InstagramEmbed = ({ embedCode }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.instagram.com/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: embedCode,
      }}
    />
  );
};

export default InstagramEmbed;