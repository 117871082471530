import Equipment from "./equipment"
import PriceTable from "./priceTable"
import SubHeadline from "./subHeadline"
import SlideShow from "./slideShow"


const LockerRoom = () => {
    return (
        <div className="locker-room">
            <SubHeadline
                title={'Locker Room'}
            />
            <div className="line"></div>
            <Equipment
                names={['鍵付きロッカー']}
                num={'first'}
            />
            <Equipment
                names={['シャワールーム（女性用更衣室）']}
            />
            <SlideShow
                images={[
                    '/images/locker-room1-min.jpg',
                    '/images/locker-room2-min.jpg',
                    '/images/locker-room3-min.jpg',
                ]}
            />
            <PriceTable 
                time={''}
                price={'無料'}
            />
            <div className="line-area"></div>
        </div>
    )
}

export default LockerRoom