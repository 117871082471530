import Equipment from "./equipment"
import PriceTable from "./priceTable"
import SubHeadline from "./subHeadline"
import SlideShow from "./slideShow"


const MeetingRoom = () => {
    return (
        <div className="meeting-room">
            <SubHeadline
                title={'Meeting Room'}
            />
            <div className="line"></div>
            <Equipment
                names={['机', '冷蔵庫', '給湯室', '椅子4脚']}
                num={'first'}
            />
            <Equipment
                names={['モニター', '電子レンジ']}
            />
            <SlideShow
                images={[
                    '/images/meeting-room1-min.jpg',
                    '/images/meeting-room2-min.jpg',
                    '/images/meeting-room3-min.jpg',
                ]}
            />
            <PriceTable 
                time={'30分'}
                price={'500円（税込）'}
            />
            <div className="line-area"></div>
        </div>
        
    )

}

export default MeetingRoom