import React, { useState } from "react";
import AnimationTrigger from "./atoms/animationTrigger";
import Top from "./top";
import About from "./about";
import Header from "./header";
import Rooms from "./rooms";
import Service from "./service";
import Schedule from "./schedule";
import Access from "./access";
import News from "./news";
import Address from "./address";
import Icons from "./icons";
import Contact from "./contact";
import Burger from "./burger";

function App() {
  const [renderIcon, setRenderIcon] = useState(false);

  const handleIcon = () => {
    setRenderIcon(true);
  }

  return (
    <React.Fragment>
      <Header />
      <Burger/>
      {renderIcon && <Icons/>}
      <Top />
      <AnimationTrigger>
        <About />
      </AnimationTrigger>
      <AnimationTrigger handleIcon={handleIcon}>
          <Rooms />
      </AnimationTrigger>
      <AnimationTrigger>
        <Service/>
      </AnimationTrigger>
      <AnimationTrigger>
        <Schedule/>
      </AnimationTrigger>
      <AnimationTrigger>
        <Access/>
      </AnimationTrigger>
      <AnimationTrigger>
        <News/>
      </AnimationTrigger>
      <AnimationTrigger>
        <Address/>
      </AnimationTrigger>
      <AnimationTrigger>
        <Contact/>
      </AnimationTrigger>
    </React.Fragment>
  );
}

export default App;
