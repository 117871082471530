import Headline from "./atoms/headline"


const Service = () => {

    return (
        <div id='service'>
            <div>
                <Headline
                    title={'Service'}
                    subTitle={'サービス'}
                />
            </div>
            <div className="block-wrapper">
                <div className="first block">
                    <img src={process.env.PUBLIC_URL + '/images/num01.png'} alt="" />
                    <div className="first">
                        <p className="topic">電話かメールで連絡</p>
                        <p className="sentence">
                            画面左側のアイコンから<br/>
                            ご連絡ください    
                        </p>
                    </div>
                </div>
                <div className="block">
                    <img src={process.env.PUBLIC_URL + '/images/num02.png'} alt="" />
                    <div className="second">
                        <p className="topic">スタジオの説明</p>
                        <p className="sentence"> 
                            ご利用当日にお早めに来ていただくか、<br/>
                            事前にスタジオに来ていただきます。<br/>
                            説明は15分ほどです
                        </p>
                    </div>
                </div>
                <div className="block">
                    <img src={process.env.PUBLIC_URL + '/images/num03.png'} alt="" />
                    <div className="third">
                        <p className="topic">ご利用</p>
                        <p className="sentence">お支払いは現金又は口座振込のみです。</p>
                    </div>
                </div>
            </div>
            <a className="term-btn"
                href={process.env.PUBLIC_URL + '/docs/term.pdf'}
                target="_blank"
                rel="noopener noreferrer"
            >
                利用規約
                <img src={process.env.PUBLIC_URL + '/images/another_window.png'} alt="" />
            </a>
            <br /><br />
            <a className="term-btn"
            href={process.env.PUBLIC_URL + 'docs/privacy.pdf'}
            target="_blank"
            rel="noopener noreferrer"
            >
                個人情報保護方針
                <img src={process.env.PUBLIC_URL + '/images/another_window.png'} alt="" />
            </a>
        </div>
    )
}

export default Service