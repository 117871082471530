import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";


const AnimationTrigger = (props) => {
    const { children } = props;
    const [ref, inView] = useInView({
        rootMargin: "-150px",
        triggerOnce: true,
    });

    useEffect(() => {
        if (props.handleIcon && inView) {
            props.handleIcon();
        }
    }, [inView, props]);
    
    return (
        <div ref={ref}>
          <div className={inView ? "scroll-animation before-scroll" : 'before-scroll'}>{children}</div>
        </div>
    );
};

export default AnimationTrigger;