import React from 'react';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


const SlideShow = (props) => {
    
    return (
        <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={10}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop
            autoplay={{ delay: 3000 }}
            speed={1000}
        >
            {props.images.map((image, index) => {
                return (
                    <SwiperSlide key={index}>
                        <img src={process.env.PUBLIC_URL + image} alt="" />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    );
}

export default SlideShow