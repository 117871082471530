const Equipment = (props) => {
    const equipmentClass = props.num === 'first' ? 'equipment first' : 'equipment'

    return (
        <div className={equipmentClass}>
            <ul>
                {props.names.map((item, index) => {
                    return <li key={index}>{item}</li>
                })}
            </ul>
        </div>
    )
}

export default Equipment