const Contact = () => {
    return (
        <div id='contact'>
            <p className="title">
                Contact
            </p>
            <p className="text">
                Tel : 0493-81-6300 <br/>
                Email : liina.dancestudio@gmail.com
            </p>
        </div>
    )
}

export default Contact