import Headline from "./atoms/headline"


const Access = () => {
    return (
        <div id='access'>
            <Headline
                title={'Access'}
                subTitle={'アクセス'}
            />
            <div className="wrap">
                    <div className="train">
                        <div className="sub-headline-wrapper">
                        <span className="sub-headline">電車でお越しの方</span>
                        </div>
                        <div className="img-area">
                            <img src={process.env.PUBLIC_URL + '/images/station-min.jpg'} alt="" />
                            <p className="text">東武東上線高坂駅東口を出て、交番を右手に直進<br/>突き当たり右手に当スタジオがあります</p>
                        </div>
                    </div>
                    <div className="parking">
                        <div className="sub-headline-wrapper">
                            <span className="sub-headline">お車でお越しの方</span>
                        </div>
                        <div className="img-area">
                            <img src={process.env.PUBLIC_URL + '/images/parking1-min.jpg'} alt="" />
                            <p className="text parking">当スタジオ敷地内に6台分の駐車場完備</p>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Access