import Headline from "./atoms/headline"

const Schedule = () => {
    return (
        <div id='schedule'>
            <Headline
                title={'Schedule'}
                subTitle={'　スケジュール'}
            />
            <img className="schedule" src={process.env.PUBLIC_URL + '/images/schedule.jpg'} alt="" />
        </div>
    )
}

export default Schedule