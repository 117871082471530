
const Headline = (props) => {
    const [firstChar, ...remainingChars] = props.title
    let titleClass = props.color === 'white' ? 'title white' : 'title'
    let subTitleClass = props.color === 'white' ? 'sub-title white' : 'sub-title'
    let imgPath

    if (props.color === 'white') {
        titleClass = 'title white'
        subTitleClass = 'sub-title white'
        imgPath = '/images/arrow_white.png'
    } else {
        titleClass = 'title'
        subTitleClass = 'sub-title'
        imgPath = '/images/arrow.png'
    }

    return (
        <div className='headline'>
            <div>
                <p className={titleClass}><span>{firstChar}</span>{remainingChars}</p>
                <p className={subTitleClass}>{props.subTitle}</p>
            </div>
            <img src={process.env.PUBLIC_URL + imgPath} alt="" />
        </div>
    )
}

export default Headline