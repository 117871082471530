import React from 'react'

const Header = () => {
    return (
        <header>
            <div>
                <a href='#top'><p>Liina Dance Studio</p></a>
            </div>
        </header>
    )
}

export default Header