import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import { Link as Scroll } from 'react-scroll';


class Burger extends React.Component {
    showSetting(event) {
        event.preventDefault();
    }

    scrollSettings = {
        className: 'menu-item',
        smooth: true,
        duration: 1000
    }
    
    render () {
        return (
            <div id='burger'>
                <div id="outer-container">
                    <Menu
                        pageWrapId={ "page-wrap" }
                        outerContainerId={ "outer-container" }
                        customBurgerIcon={ <img src="/images/header_button.png" alt="" /> }
                        right
                    >
                        <main id="page-wrap">
                            <p className='title'>Liina Dance Studio</p>
                            <div className='menu-item-wrapper'>
                                <Scroll to='top' {...this.scrollSettings}>Top</Scroll>
                                <Scroll to='about' {...this.scrollSettings}>About</Scroll>
                                <Scroll to='rooms' {...this.scrollSettings}>Rooms</Scroll>
                                <Scroll to='service' {...this.scrollSettings}>Service</Scroll>
                                <Scroll to='schedule' {...this.scrollSettings}>Schedule</Scroll>
                                <Scroll to='access' {...this.scrollSettings}>Access</Scroll>
                                <Scroll to='news' {...this.scrollSettings}>News</Scroll>
                                <Scroll to='address' {...this.scrollSettings}>Address</Scroll>
                            </div>
                            <div className='contact'>
                                <div className='text'>
                                    〒355-0047<br/>
                                    埼玉県東松山市高坂1034-7<br/>
                                    0493-81-6300<br/>
                                    liina.dancestudio@gmail.com
                                    
                                </div>
                                <a href='https://www.instagram.com/liina_dancestudio' target="_blank" rel="noopener noreferrer">
                                <p className='instagram'>INSTAGRAM</p>
                                </a>
                            </div>
                            <a onClick={ this.showSettings } className="menu-item--small" href="" alt="">Settings</a>
                        </main>
                    </Menu>
                </div>
            </div>
        )
    }
    
}

export default Burger