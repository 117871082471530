import Headline from "./atoms/headline"

const Address = () => {
    return (
        <div id='address'>
            <Headline
                title={'Address'}
                subTitle={'所在地'}
                color={'white'}
            />
            <p className="text">
                〒355-0047<br/>
                埼玉県東松山市高坂1034-7
            </p>
            <iframe 
                title="Example Website"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3227.7843842036073!2d139.396324215618!3d36.00113461996742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018d5b1032d920f%3A0xe0e1dadf074a8eb9!2z44CSMzU1LTAwNDcg5Z-8546J55yM5p2x5p2-5bGx5biC6auY5Z2C77yR77yQ77yT77yU!5e0!3m2!1sja!2sjp!4v1679579690459!5m2!1sja!2sjp"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    )
}

export default Address