import AnimationTrigger from "./atoms/animationTrigger"
import Headline from "./atoms/headline"
import LockerRoom from "./atoms/lockerRoom"
import MeetingRoom from "./atoms/meetingRoom"
import Studio from "./atoms/studio"

const Rooms = () => {
    return (
        <div id='rooms'>
            <Headline
                title={'Rooms'}
                subTitle={'各部屋について'}
                color={'white'}
            />
            <Studio />
            <div className="wrap">
                <div className="other-wrap">
                    <AnimationTrigger rootMargin={'-100px'}>
                        <MeetingRoom />
                    </AnimationTrigger>
                </div>
                <div className="other-wrap">
                    <AnimationTrigger rootMargin={'-100px'}>
                        <LockerRoom />
                    </AnimationTrigger>
                </div>
            </div>
        </div>
    )
}

export default Rooms