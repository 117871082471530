import Equipment from "./equipment"
import PriceTable from "./priceTable"
import SubHeadline from "./subHeadline"
import SlideShow from "./slideShow"


const Studio = () => {
    return (
        <div className="studio">
            <SubHeadline
                title={'Studio'}
            />
            <div className="line"></div>
            <Equipment
                names={['大型鏡', 'モニター', 'リノリウム', '撮影用ライト']}
                num={'first'}
            />
            <Equipment
                names={['レッスン用バー', 'スピーカー(Bluetooth©︎対応)']}
            />
            
            <SlideShow
                images={[
                    '/images/studio1-min.jpg',
                    '/images/studio2-min.jpg',
                    '/images/studio3-min.jpg',
                ]}
            />
            <Equipment
                names={['高さ：3.3m　　広さ：56.6m²(7.5m x 7.5m)']}
            />
            <PriceTable 
                time={'30分'}
                price={'1000円（税込）'}
            />
            <div className="line-area"></div>
        </div>
    )
}

export default Studio